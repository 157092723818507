<template>
  <v-form
    ref="policyForm"
    lazy-validation
    @submit.prevent="addPolicy"
  >
    <v-row>
      <v-col cols="12">
        <v-radio-group
          v-model="policy.user"
          row
          :disabled="fromClient"
          hide-details
        >
          <v-radio
            label="Company"
            :value="false"
          />
          <v-radio
            label="User"
            :value="true"
          />
        </v-radio-group>
      </v-col>
      <template>
        <v-col
          v-if="policy.user"
          cols="12"
        >
          <v-autocomplete
            v-model="policy.user_id"
            label="User"
            :items="mixinItems.users"
            item-text="first_name"
            item-value="id"
            :loading="loadingMixins.users"
            :disabled="fromClient"
          />
        </v-col>
        <v-col
          v-else
          cols="12"
        >
          <v-autocomplete
            v-model="policy.company_id"
            label="Company"
            :items="mixinItems.companies"
            item-text="name"
            item-value="id"
            :loading="loadingMixins.companies"
            :disabled="fromClient"
          />
        </v-col>
      </template>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="policy.policy_type_id"
          label="Policy Type"
          :items="mixinItems.policyTypes"
          item-text="name"
          item-value="id"
          :loading="loadingMixins.policyTypes"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-if="policy.policy_type_id === packagePolicyType"
          v-model="policy.package_type_id"
          label="Package Type"
          :items="mixinItems.packageTypes"
          item-text="name"
          item-value="id"
          :loading="loadingMixins.packageTypes"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="policy.policy_number"
          label="Policy Number *"
          :rules="[required]"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="policy.binder_number"
          label="Binder Number"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="policy.term_id"
          label="Term"
          :items="mixinItems.terms"
          item-text="name"
          item-value="id"
          :loading="loadingMixins.terms"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="policy.finance_option_id"
          label="Finance Option"
          :items="mixinItems.financeOptions"
          item-text="name"
          item-value="id"
          :loading="loadingMixins.financeOptions"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="policy.billing_type_id"
          label="Billing Type"
          :items="mixinItems.billingTypes"
          item-text="name"
          item-value="id"
          :loading="loadingMixins.billingTypes"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="policy.premium_sent_id"
          label="Premium Sent"
          :items="mixinItems.premiumSents"
          item-text="name"
          item-value="id"
          :loading="loadingMixins.premiumSents"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="policy.base_premium"
          label="Base Premium"
          type="number"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="policy.status_type_policy_id"
          label="Status"
          :loading="loadingMixins.policyStatuses"
          :items="mixinItems.policyStatuses"
          item-text="name"
          item-value="id"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-menu
          v-model="effectiveDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="policy.effective_date"
              label="Effective Date"
              prepend-icon="mdi-calendar-clock"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="policy.effective_date"
            no-title
            color="primary"
            @input="effectiveDate = false"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-menu
          v-model="expirationDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="policy.expiration_date"
              label="Expiration Date"
              prepend-icon="mdi-calendar-refresh"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="policy.expiration_date"
            no-title
            color="primary"
            @input="expirationDate = false"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-menu
          v-model="binderDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="policy.binder_date"
              label="Binder Date"
              prepend-icon="mdi-calendar-clock"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="policy.binder_date"
            no-title
            color="primary"
            @input="binderDate = false"
          />
        </v-menu>
      </v-col>
    </v-row>

    <div class="text-right">
      <v-btn
        small
        type="submit"
        color="success"
        :loading="adding"
      >
        <v-icon left>
          mdi-content-save
        </v-icon>
        Add
      </v-btn>
    </div>
  </v-form>
</template>

<script>
  import moment from 'moment'
  import { Api } from '@/apis'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { packagePolicyType, MIXINS, annualTermId } from '@/constants'

  export default {
    mixins: [
      fetchInitials([
        MIXINS.policyTypes,
        MIXINS.packageTypes,
        MIXINS.terms,
        MIXINS.financeOptions,
        MIXINS.billingTypes,
        MIXINS.premiumSents,
        MIXINS.users,
        MIXINS.companies,
        MIXINS.policyStatuses,
      ]),
    ],

    props: {
      fromClient: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      required: value => !!value || 'This field is required.',
      policy: {
        user: false,
        policy_number: 'Pending',
        term_id: annualTermId,
      },
      adding: false,
      effectiveDate: false,
      expirationDate: false,
      binderDate: false,
      packagePolicyType,
    }),

    watch: {
      'policy.effective_date': {
        handler (value) {
          if (this.policy.term_id === annualTermId) {
            this.policy.expiration_date = moment(value).add(1, 'years').format('YYYY-MM-DD')
          }
        },
      },
    },

    mounted () {
      if (this.fromClient) {
        const user = this.$route.params.id.split('-')[0]
        const id = this.$route.params.id.split('-')[1]
        this.policy.user = user === '1'
        this.policy.company_id = parseInt(id)
        this.policy.user_id = parseInt(id)
      }
    },

    methods: {
      async addPolicy () {
        if (this.$refs.policyForm.validate()) {
          this.adding = true
          await new Api().post({
            url: 'policies/policy',
            request: this.policy,
          })
          this.adding = false
          this.$refs.policyForm.reset()
          this.$emit('close')
        }
      },
    },
  }
</script>
